<template>
  <div class="flex flex-col mb-12 mt-5">
    <update-prompt
      :isActive="activePrompt"
      :isApprove="isApprove"
      @closePrompt="closePrompt"
      @reloadDataFromChild="reloadTable"
      :selectedData="selectedData"
    />
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr
          :data="data"
          :key="index"
          v-for="(data, index) in responseData.customer_invoice_exchanges"
        >
          <!-- <vs-td class="whitespace-no-wrap" style="width: 70px">
            <div class="flex justify-between gap-4">
              <vx-tooltip text="Approve">
                <vs-button
                  color="blue"
                  type="line"
                  icon="icon-check"
                  icon-pack="feather"
                  @click="showPrompt(data)"
                />
              </vx-tooltip>
              <vx-tooltip text="Reject">
                <vs-button
                  color="red"
                  type="line"
                  icon="icon-x"
                  icon-pack="feather"
                  @click="showPrompt(data, false)"
                />
              </vx-tooltip>
            </div>
          </vs-td> -->
          <vs-td>{{ data.sold_to_code }}</vs-td>
          <vs-td>{{ data.customer_name }}</vs-td>
          <vs-td>{{ data.sold_to_address }}</vs-td>
          <vs-td><schedule-display :schedules="data.schedules" /></vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import scheduleDisplay from "../schedule-display.vue";
import updatePrompt from "./request-prompt.vue";
export default {
  components: {
    "schedule-display": scheduleDisplay,
    "update-prompt": updatePrompt,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        // {
        //   text: "Action",
        // },
        {
          text: "Sold To Code",
          value: "sold_to_code",
        },
        {
          text: "Customer",
          value: "customer_code",
        },
        {
          text: "Address",
        },
        {
          text: "Schedules",
        },
      ],
      responseData: {},
      activePrompt: false,
      isTableReload: false,
      isApprove: true,
      selectedData: {},
    };
  },
  watch: {
    isTableReload() {
      if (this.isTableReload) {
        this.reloadData(this.params);
        this.isTableReload = false;
      }
    },
  },
  methods: {
    reloadData(params) {
      //   this.params = {
      //     ...params,
      //     territory_ids:
      //       this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      //   };
      console.log("params", params);
      this.params = params;
      console.log(this.params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code > 299) {
          this.$vs.loading.close();
          this.$vs.notify({
            position: "top-right",
            title: "Error",
            text: r.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/customer-invoice-exchange/data-table", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              status: 1,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getSchedule(sch) {
      return JSON.parse(sch);
    },
    showPrompt(selected, isApproval = true) {
      this.selectedData = selected;
      this.activePrompt = true;
      this.isApprove = isApproval;
    },
    closePrompt() {
      this.selectedData = {};
      this.activePrompt = false;
    },
    reloadTable() {
      this.isTableReload = true;
    },
  },
};
</script>
