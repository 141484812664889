<template>
  <div class="flex flex-col mb-12 mt-5">
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr
          :data="data"
          :key="index"
          v-for="(data, index) in responseData.customer_invoice_exchanges"
        >
          <vs-td>
            <vx-tooltip text="Edit">
              <vs-button
                color="Green"
                type="line"
                icon="icon-edit"
                icon-pack="feather"
                :to="{
                  path: '/finance/customer-invoice-exchange/detail/'+data.ID,
                }"
              />
            </vx-tooltip>
          </vs-td>
          <vs-td>{{ data.customer_name }}</vs-td>
          <vs-td>{{ data.sold_to_code }}</vs-td>
          <vs-td>{{ data.sold_to_address }}</vs-td>
          <vs-td><schedule-display :schedules="data.schedules" /></vs-td>
          <vs-td>{{ data.active == "Active" ? data.active:"Inactive" }}</vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>
<script>
import scheduleDisplay from "../schedule-display.vue";
export default {
  components: {
    "schedule-display": scheduleDisplay,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Action",
        },
        {
          text: "Customer",
          value: "customer_code",
        },
        {
          text: "Sold To Code",
          value: "sold_to_code",
        },
        {
          text: "Address",
        },
        {
          text: "Schedules",
        },
        {
          text: "Status",
        },
      ],
      responseData: {},
    };
  },

  methods: {
    reloadData(params) {
      //   this.params = {
      //     ...params,
      //     territory_ids:
      //       this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      //   };
      console.log("params", params);
      this.params = params;
      console.log(this.params);
      this.$vs.loading();

      const dataTable = this.datatable(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code > 299) {
          this.$vs.loading.close();
          this.$vs.notify({
            position: "top-right",
            title: "Error",
            text: r.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        } else if (r.code == 200) {
          this.$vs.loading.close();
          // this.data = r.data.payment;
          this.responseData = r.data;
          console.log("r.data", r.data);
          // this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    datatable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/customer-invoice-exchange/data-table", {
            params: {
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.order,
              sort: params.sort,
              status: 2,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
</script>
