<template>
  <div class="tab-text">
    
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <input
          class="inputx"
          type="file"
          v-if="uploadReady"
          ref="file"
          name="file"
          accept=".xls, .xlsx"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-button class="ml-auto mt-2" v-on:click="handleSubmit"
          >Submit</vs-button
        >
        <!-- <vs-button
                    class="ml-4 mt-2"
                    type="border"
                    color="warning"
                    v-on:click="handleDownload"
                    >Download Template</vs-button> -->
      </div>
    </div>
    <vs-alert v-if="errorMessage.length > 0" color="danger">
      <template v-for="dt in errorMessage">
        <div v-bind:key="dt" class="mb-2">
          {{ dt }}
        </div>
      </template>
    </vs-alert>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="
                  p-3
                  border border-solid
                  d-theme-border-grey-light
                  rounded-full
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-between
                  font-medium
                "
              >
                <span class="mr-2"
                  >{{ this.table.start }} - {{ this.table.end }} of
                  {{ this.table.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>No</vs-th>
            <vs-th>Description</vs-th>
            <!-- <vs-th sort-key="params">Total Data</vs-th> -->
            <vs-th sort-key="file">File</vs-th>
            <vs-th sort-key="date">Date</vs-th>
            <vs-th sort-key="date">User</vs-th>
            <vs-th sort-key="date">Status</vs-th>
            <vs-th sort-key="date">Action</vs-th>
          </template>
          <!-- <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ indextr + 1 }}</vs-td>
              <vs-td :data="data[indextr].description">{{
                data[indextr].description
              }}</vs-td>
              
             <vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">Uploaded file</a></vs-td>
              <vs-td :data="data[indextr].date">{{ data[indextr].time }}</vs-td>
              <vs-td :data="data[indextr].date">{{ data[indextr].username }}</vs-td>
              <vs-td :data="data[indextr].total_data">
                Total Document : {{data[indextr].total_data}} <br>
                Total Success : {{data[indextr].detail_activity.filter((v) => {
                      return v.Status == "Success";
                    }).length}} <br>
                Total Failed : {{data[indextr].detail_activity.filter((v) => {
                    return v.Status != "Success";
                  }).length}}<br>
              </vs-td>
              <vs-td>
                <a
                  ><vs-button
                    class="mt-2 ml-2"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-search"
                    v-on:click="showDetail(indextr)"
                  ></vs-button
                ></a>
              </vs-td>
            </vs-tr>
          </template> -->
          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ indextr + 1 }}</vs-td>
              <vs-td :data="data[indextr].description">{{
                data[indextr].description
              }}</vs-td>
              
              <vs-td :data="data[indextr].file"><a href="#" v-on:click="downloadFileAwsS3(tr.file)">Uploaded file</a></vs-td>
              <vs-td :data="data[indextr].date">{{ dateFormatGMT7(tr.time) }}</vs-td>
              <vs-td :data="data[indextr].date">{{ data[indextr].username }}</vs-td>
              <vs-td :data="data[indextr].total_data">
                Total Document : {{data[indextr].total_data}} <br>
                Detail Success : {{ tr.document_activity_success }} <br>
							  Detail Failed : {{ tr.document_activity_failed }}
              </vs-td>
              <vs-td>
                <a
                  ><vs-button
                    class="mt-2 ml-2"
                    color="primary"
                    type="border"
                    icon-pack="feather"
                    icon="icon-search"
                    v-on:click="showDetail(tr)"
                  ></vs-button
                ></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
      <vs-popup fullscreen title="Log Details" :active.sync="detail">
			<div class="vx-row mb-6">
				<div class="vx-col w-full" v-if="detail">
					<datatableDetail :selected="selected"></datatableDetail>
				</div>
			</div>
		</vs-popup>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-list>
            <vs-list-item
              v-for="item in selected.detail_activity"
              :key="item.ReferenceCode"
              :subtitle="
                'Transaction:' +
                JSON.parse(item.JsonData)[1] +
                ', VA :' +
                JSON.parse(item.JsonData)[4] +
                ', Note :' +
                JSON.parse(item.JsonData)[11] +
                ' => ' +
                item.Status +
                ', ' +
                item.Note
              "
            >
            </vs-list-item>
          </vs-list>
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import datatableDetail from "./datatable-detail.vue";
export default {
  components: {
    Datepicker,
    datatableDetail,
  },
  data() {
    return {
      uploadReady: true,
      errorMessage: [],
      operatingUnit: {},
      optionOU: [],
      postingDate: null,
      detail: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      },
    };
  },
  methods: {
    getOptionOU() {
      this.$http.get("/api/v1/master/operating-unit").then((resp) => {
        this.optionOU = resp.data.records;
        this.operatingUnit = resp.data.records[0];
      });
    },
    handleDownload() {
      // window.open(process.env.VUE_APP_CDN_ADDRESS+'/'+process.env.VUE_APP_CDN_BUCKET+'/templates/TemplateCustomer.xlsx');
    },
    dateFormatGMT7(date) {
          if (date) {
      // ini karena datanya berformat `date time`, bukan `datetimezone`
      // jadi GMT(+7) ditambah +7 =>utc offset = 14
              return moment(String(date)).utcOffset(7).format('DD/MM/YYYY HH:mm')
          }
      },
    handleSubmit() {
      this.errorMessage = [];
      var file = this.$refs.file.files[0];
      if (file == null) {
        this.$vs.notify({
          color: "danger",
          title: "Form Validation",
          text: "File is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        var dateNow = new Date();
        var now =
          dateNow.getFullYear() +
          "" +
          (dateNow.getMonth() + 1) +
          "" +
          dateNow.getDate();
        now +=
          +"" +
          dateNow.getHours() +
          "" +
          dateNow.getMinutes() +
          "" +
          dateNow.getSeconds();
        this.formData = new FormData();
        this.formData.append("file", file);
        this.formData.append("user_agent", navigator.userAgent);
        this.formData.append("file_name", now + "_" + file.name);
        this.formData.append(
          "territory_id",
          this.operatingUnit.OperatingUnitTerritory.TerritoryID
        );
        this.formData.append(
          "posting_date",
          moment(this.postingDate).format("YYYY-MM-DD")
        );
        this.formData.append("url", "/api/v1/bank-statement");
        this.$vs.loading();
        this.$http
          .post("/api/v1/bank-statement", this.formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((resp) => {
            console.log(resp);
            this.$vs.loading.close();
            this.uploadReady = false;
            this.$nextTick(() => {
              this.uploadReady = true;
            });
            if (resp.status == "success") {
              this.$vs.notify({
                color: "success",
                title: "Success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              
              this.getData();
            } else {
              console.log(resp.data);
              this.errorMessage.push(resp.message)
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
              this.getData();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: error,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.getData();

          });
      }
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    closeDetail() {
        this.detail = false;
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    showDetail(data) {
      this.selected = data.id;
			this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/import/history", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            type: "import-bank-statement",
          },
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.message);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  mounted() {
    this.getOptionOU();
  },
  watch: {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>

 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>