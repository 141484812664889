<template>
  <vx-card title="Customer Invoice Exchange">
    <vs-tabs>
      <vs-tab label="Upload">
        <div class="con-tab-ejemplo">
          <upload />
        </div>
      </vs-tab>
      <vs-tab label="Draft">
        <div class="con-tab-ejemplo">
          <draft />
        </div>
      </vs-tab>
      <vs-tab label="Waiting Approval">
        <div class="con-tab-ejemplo">
          <waiting />
        </div>
      </vs-tab>
      <vs-tab label="Approved">
        <div class="con-tab-ejemplo">
          <approve />
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="con-tab-ejemplo"><reject /></div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import upload from "./upload/upload.vue";
import draft from "./draft/index.vue";
import approve from "./approve/index.vue";
import reject from "./reject/index.vue";
import waiting from "./waiting/index.vue";
import Upload from "../bank_statement/upload/upload.vue";
export default {
  components: {
    draft,
    approve,
    reject,
    waiting,
    upload,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
